<template>
	<div class="sycm-page">
		<div class="sycm-section sycm-filter">
			<div class="filter-main">{{$t('sycm.common.statisticalTime')}} {{ parseInt(Date.now()/1000) | parseTime()}}</div>
            <ul class="filter-bar">
                <li class="bar-item"><el-button type="primary" size="mini">{{$t('sycm.actions.realTime')}}</el-button></li>
                <li class="bar-item">
					<el-dropdown class="sycm-bar-dropdown" placement="bottom" @command="handleCommand">
						<span class="el-dropdown-link">
							{{ terminalType | terminalFilter($t('sycm.dropdown.terminal')) }}<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item v-for="(item,index) in $t('sycm.dropdown.terminal')" :key="index" :command="item.value">{{item.label}}</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
                </li>
            </ul>
		</div>
		<div class="sycm-section">
			<div class="section-head">
				<div>
					<div class="title">{{$t('sycm.common.sourceScatter')}}</div>
					<ul class="head-menu">
						<li v-for="(item,index) in $t('sycm.options.ipollSource')" :key="index" :class="{active:activeTab==index}" @click="handleMenu(index)">{{item}}</li>
					</ul>
				</div>
			</div>
			<div class="section-main">
				<div class="sycm-map">
					<div class="map-chart">
						<chart id="map-chart" v-if="mapData" :map-data="mapData" :map-name="activeTab == 1 ? $t('sycm.common.payBuyers') : $t('sycm.common.visitor')" :visual-max="mapMax" height="100%" width="100%" />
					</div>
					<div class="map-rank">
						<table class="rank-table" style="width: 100%">
							<tr>
								<th width="50px">{{$t('sycm.tableHead.region')}}</th>
								<th width="65px"></th>
								<template v-if="activeTab!==1">
									<th width="155px">{{$t('sycm.common.visitor')}}</th>
									<th width="100px" align="right">{{$t('sycm.common.payBuyers')}}</th>
								</template>
								<template v-else>
									<th width="155px">{{$t('sycm.common.payBuyers')}}</th>
									<th width="100px" align="right">{{$t('sycm.common.visitor')}}</th>
								</template>
							</tr>
							<template v-for="(item,index) in sourceData">
								<tr v-if="activeTab!==1 || (activeTab==1 && item.buyers>0)" :key="index">
									<td align="center">{{item.province_name}}</td>
									<template v-if="activeTab!==1">
										<td align="right">{{item.visitors}}</td>
										<td><div class="rank-bar" :class="index | colorFileter()" :style="'width: '+(item.visitors/visitorsMax*100)+'%'"></div></td>
										<td align="right">{{item.buyers}}</td>
									</template>
									<template v-else>
										<td align="right">{{item.buyers}}</td>
										<td><div class="rank-bar" :class="index | colorFileter()" :style="'width: '+(item.buyers/buyersMax*100)+'%'"></div></td>
										<td align="right">{{item.visitors}}</td>
									</template>
								</tr>
							</template>
						</table>
						<div v-if="!sourceData.length>0" class="rank-empty">{{$t('sycm.empty.rank')}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Chart from '@/components/echart/mapChart'
import { fetchIpollSource } from "@/api/sycm"
export default {
	components: { Chart },
	filters: {
		terminalFilter(type, options){
			if(type=='') {
				type = 0
			}
			return options[type].label
		},
		colorFileter(type) {
			const options = ['blue', 'yellow', 'orange']
			return options[type]
		}
	},
	data() {
		return {
			terminalType: '',
			sourceData: [],
			activeTab: 0,
			mapData: null,
			visitorsMax: 0,
			buyersMax: 0,
			mapMax: 500
		}
	},
	created () {
		this.getData()
	},
	methods: {
		async getData(){
			const loading = this.$loading()
			try {
				const params = {
					uuid: this.$store.getters.uuid,
					source: this.terminalType,
					type: this.activeTab
				}
				const { data } = await fetchIpollSource(params)
				this.sourceData = data.list
				const mapData = []
				let visitorsMax = 0
				let buyersMax = 0
				data.list.forEach(item => {
					switch(this.activeTab) {
						case 0:
							mapData.push({name: item.province_name,value: item.visitors})
						break;
						case 1:
							mapData.push({name: item.province_name,value: item.buyers})
						break;
					}
					if(item.visitors>visitorsMax) {
						visitorsMax = item.visitors
					}
					if(item.buyers>buyersMax) {
						buyersMax = item.buyers
					}
				})
				this.visitorsMax = visitorsMax
				this.buyersMax = buyersMax
				const max = this.activeTab !== 1 ? visitorsMax : buyersMax
				this.mapMax = max > 0 ? max : 500
				this.$nextTick(()=>{
					this.mapData = mapData
				})
			} catch (error) {
				console.log(error)
			}
			loading.close()
		},
		handleCommand(command) {
			this.terminalType = command
			this.getData()
		},
		handleMenu(val) {
			this.mapData = null
			this.activeTab = val
			this.getData()
		}
	}
}
</script>

<style lang="scss" scoped>
.sycm-page {
	.sycm-section {
		margin-bottom: 10px;
		box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
		background-color: #ffffff;
		.section-head {
			display: flex;
			padding: 0 30px;
			line-height: 50px;
			justify-content: space-between;
			border-bottom: 1px solid #e6e6e6;
			.title {
				font-size: 16px;
				font-weight: bold;
				display: inline-block;
				vertical-align: top;
			}
			.head-menu {
				display: inline-block;
				vertical-align: top;
				margin-left: 40px;
				li {
					display: inline-block;
					vertical-align: top;
					font-size: 14px;
					margin: 0 10px;
					line-height: 46px;
					cursor: pointer;
					border-top: 2px solid transparent;
					border-bottom: 2px solid transparent;
					&.active {
						color: $--sycm-primary;
						border-bottom-color: $--sycm-primary;
					}
				}
			}
			.barbox {
				span {
					margin-right: 10px;
				}
			}
		}
		.section-main {
			min-height: 100px;
			padding: 0 30px 30px 30px;
		}
	}
	.sycm-filter {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 30px;
		.filter-main {
			font-size: 12px;
			line-height: 1.5;
			color: #333333;
		}
		.filter-bar {
            display: flex;
			.bar-item {
				margin-left: 10px;
			}
		}
	}
	.sycm-map {
		display: flex;
		justify-content: space-between;
		.map-chart {
			width: 500px;
			height: 375px;
		}
		.map-rank {
			width: 370px;
			padding-top: 30px;
			font-size: 12px;
			line-height: 1.5;
			.rank-table {
				th {
					color: $--sycm-primary;
				}
				th,td {
					padding: 4px;
				}
			}
			.rank-empty {
				height: 280px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.rank-bar {
				height: 16px;
				width: 100%;
				background-color: #4cb5ff;
				&.blue {
					background-color: $--sycm-primary;
				}
				&.yellow {
					background-color: #f3d024;
				}
				&.orange {
					background-color: #ff8533;
				}
			}
		}
	}
}
</style>